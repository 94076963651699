@desktop_skalavalidering_bredde: 475px;

.sporsmalsliste {
  list-style: none;
  padding: 0;
}

.sporsmal__innhold {
  margin-top: 6.5rem;
}

.sporsmal.videresporsmal:not(.tilbake) {
  .sporsmal__innhold {
    animation: slideUp 500ms both;
  }
  .alternativer, .validering, .skalavalidering, .sporsmal__knapp, .sporsmal__knapp-tilbake, .knapp--send, .tips {
    animation: slideUp 550ms both;
  }
}

.sporsmal.tilbake {
  .alternativer, .validering, .skalavalidering, .sporsmal__knapp, .sporsmal__knapp-tilbake, .knapp--send, .tips {
    animation: slideDown 550ms both;
  }
  .sporsmal__innhold {
    animation: slideDown 550ms both;
  }
}

.forstesporsmal {
  animation: fadeIn 1s both;
}

.sporsmal {
  padding: 1rem 1.4rem;
  min-height: 99vh;

  &__overskrift {
    padding: 0 12%;
  }
  &__ingress {
    margin-bottom: 1rem;
  }

  &__start {
    text-align: center;
    margin: 0 0 2rem;
  }

  &__ikon {
    display: inline-block;
    margin: 0 auto 1rem;
    width: 43.3px;
    height: 60px;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(-100vh);
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(100vh);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (min-width: @screen-sm-min) {
  .sporsmal {

    &__innhold {
      margin-top: 7rem;
    }

    &__ikon {
      width: 65px;
      height: 90px;
    }

    &__overskrift {
      padding: 0 25%;
    }

    .skalavalidering {
      margin: 0 auto;
      width: @desktop_skalavalidering_bredde;
    }
  }
}

@media (min-width: @screen-md-min) {
  .limit.limit__sporsmal {
    width: @tabletLimitWidth;
  }
}