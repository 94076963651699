.framdrift {
	background-color: @navLysGra;
	z-index: 10000;
	.andel {
		height: 10px;
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
		background-color: @navGra60;
		transition: width ease-in-out 1s;
	}
	&.framdrift-fixed {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
	}
}
