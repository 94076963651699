.tips {
    border-left: 2px solid @navDypBla;
    color: @navDypBla;
    padding-left: 0.4rem;
    animation: fadeIn 1s both;

    .tips__overskrift {
        margin-bottom: 0.4rem;
    }
}
