.pil-mixin(@color, @rotation) {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-right: 2px solid @color;
    border-bottom: 2px solid @color;
    transform: rotate(@rotation);
    margin-top: 15px;
}

.gjemt-mixin() {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

.sporsmal__knapp {
    display: block;
    margin: 2rem auto;
    text-align: center;
    border-radius: 50%;
    border: 1.5px solid @navMorkGra;
    width: 48px;
    height: 48px;
    &:after {
        .pil-mixin(@navMorkGra, 45deg);
    }
    &:focus {
        outline: none;
        box-shadow: 0 0 0 3px @orangeFocus;
        background-color: @white;
    }
    &.erBesvart {
        border-color: @navBla;
        background-color: @navBla;
        &:after {
            .pil-mixin(@white, 45deg);
        }
        &:focus {
            background-color: @navBla;
        }
    }

    .gjemt {
        .gjemt-mixin();
    }
}

.sporsmal__knapp--IE {
    display: block;
    margin: 2rem auto;
    text-align: center;
    background-color: @navBla;
    color: @white;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    &:focus {
        outline: none;
        box-shadow: 0 0 0 3px @orangeFocus;
        background-color: @navBla;
    }
    &:after {
        .pil-mixin(@white, 45deg);
    }

    .gjemt {
        .gjemt-mixin();
    }
}

.sporsmal__knapp-tilbake {
    display: inline-block;
    position: absolute;
    top: 4rem;
    left: ~'calc(50vw - 25px)';
    padding: 3px 0;
    background: none;
    cursor: pointer;
    border-bottom: 1px solid @navGra40;
    text-decoration: none;
    color: @navMorkGra;

    &:before {
        .pil-mixin(@navMorkGra, 225deg);
        position: absolute;
        margin-top: -16px;
        left: ~'calc(50% - 6px)';
    }

    .gjemt {
        .gjemt-mixin();
    }
}

.forstesporsmal {
    .sporsmal__knapp-tilbake {
        left: ~'calc(50vw - 50px)';
    }
}

.knapp--send--IE {
    .knappbase-mixin('Source Sans Pro');
    .knappfylt-mixin(@navBla, @white, @navBla, @navBla);
    display: block;
    margin: 2rem auto;
    width: 200px;
    text-align: center;
}
.knapp--send {
    .knappbase-mixin('Source Sans Pro');
    .knappfylt-mixin(@navGra40, @white, @navGra40, @navGra40);
    display: block;
    margin: 2rem auto;
    width: 200px;
    text-align: center;

    &.erBesvart {
        .knappfylt-mixin(@navBla, @white, @navBla, @navBla)
    }
}

.knapp--full {
    width: 100%;
    padding: 1em 3.5em ~'calc(1em + .125em + 1px)' 3.5em;
}

@media (orientation: landscape), (min-width: @screen-sm-min) {
    .sporsmal__knapp-tilbake {
        top: 4.5rem;
        left: 346px;
    }
    .forstesporsmal {
        .sporsmal__knapp-tilbake {
            left: 320px;
        }
    }
    .knapp--full {
        &.knapp {
            width: auto;
            padding: ~'calc(.6em - 1px)' ~'calc(2.5em - 2px)' ~'calc(.525em - 1px)' ~'calc(2.5em - 2px)';
        }
        &.knapp--hoved {
            padding: .6em 2.5em ~'calc(.4em + .125em + 1px)' 2.5em;
        }
    }
    .resultat__knapper {
        .knapp--full {
            &.knapp {
                padding: ~'calc(1em - 1px)' ~'calc(3.5em - 2px)' ~'calc(.9em - 1px)' ~'calc(3.5em - 2px)';
            }
            &.knapp--hoved {
                padding: 1em 3em ~'calc(.6em + .225em + 2px)' 3.5em;
            }
        }
    }
}

