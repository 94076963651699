.resultat__kulepunkter {
	background-color: @foGraSectionBackground;
	padding: 2.5rem 3rem 3.5rem 3rem;
	border-radius: 8px;
	margin-bottom: 2.5rem;

	img {
		display: none;
	}
	.kulepunkter__overskrift {
		text-align: center;
	}
	.kulepunktliste {
		list-style: none;
		padding-left: 0;
		margin-top: 2rem;

		.kulepunkt {
			background: url('/ikoner/ok-sirkel-fylt-sort-hake.svg') no-repeat;
			background-position: left 0 top 2px;
			background-size: 24px 24px;
			padding: 0 0 1.3rem 2.4rem;
			&:last-child {
				padding-bottom: 0.5rem;
			}
		}
	}
}
@media (min-width: @screen-sm-min) {
	.resultat__kulepunkter {
		margin: 2rem 0 4rem;
		padding: 2rem 4rem 2rem 4rem;
		display: flex;
		align-items: center;
		justify-content: space-evenly;

		.kulepunkter__bilde {
			display: block;
			width: 35%;
			height: 35%;
			margin: 1rem 5rem 1rem 0;
		}
		.kulepunkter__innhold {
			padding-left: 0;
			width: 50%;
			.kulepunkter__overskrift {
				text-align: left;
			}
			.kulepunktliste .kulepunkt {
				padding: 0 1.8rem 1.3rem 2.4rem;
			}
		}
	}
}
