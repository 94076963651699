@import '../../farger';

.modal {
    border-radius: 8px;
    border: solid 2px @foBlaBackground;
    padding: 0;
    overflow: visible;
    position: absolute;
    top: 13.53rem;
    left: 1rem;
    right: 1rem;

    .wrapper {
        .modal__ikon {
            position: absolute;
            left: 50%;
            top: 0;
            z-index: 2000;
            transform: translate(-50%, -50%);
            background: @foBlaBackground;
            border-radius: 100%;
            height: 5rem;
            width: 5rem;

            & > img {
                z-index: 3000;
                display: block;
                position: absolute;
                height: 5rem;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .modal__tekst {
            margin: 2.6rem 1rem 0;
            padding: 1rem 0;
            text-align: center;
        }
        .modal__knapper {
            width: 100%;
            margin: 0 auto .5rem;
            padding: 0 1rem;
            display: flex;
            justify-content: space-between;
            .knapp {
                margin-bottom: .8rem;
            }
            .knapp--full .knapp--hoved {
                padding: .6em 2.5em calc(.4em + .125em + 1px);
            }
            .knapp--full {
                padding: calc(.6em - 1px) calc(2.5em - 2px) calc(.525em - 1px);
            }
            .knapp--full {
                width: calc(50% - 0.5rem);
            }
        }
    }
}

@media (min-width: 767px) {
    .modal {
        width: 518px;
        top: 20.15rem;
        left: 50%;
        -webkit-transform: translate(-50%);
        transform: translate(-50%);

        .wrapper {
            .modal__ikon {
                height: 6.25rem;
                width: 6.25rem;

                & > img {
                    height: 6.25rem;
                }
            }
            .modal__tekst {
                margin: 3.2rem 5.125rem 0;
                width: 350px;
            }
            .modal__knapper {
                width: 70%;
                margin-bottom: 2rem;
                text-align: center;
                .knapp {
                    margin-bottom: 0;
                    margin-right: .8rem;
                }
            }
        }
    }
}




