@import '../node_modules/nav-frontend-core/less/_variabler';
@import (reference) '../node_modules/nav-frontend-knapper-style/src/mixins';
@import '../node_modules/nav-frontend-chevron-style/src/index';

@import './knapper';
@import './startside/startside';
@import './tips/tips';
@import './sporsmal/sporsmal';
@import './sporsmal/framdrift';
@import './alternativ/alternativ';
@import './resultat/resultat';
@import './resultat/kulepunkt/kulepunkter';
@import './resultat/oppsummering/oppsummering';
@import './raadside/raadside';
@import './resultat/lagring/lagre-resultater-knapp';
@import 'api/datalaster';

@navLysBlaLighten90: mix(@navLysBla, #fff, 10%);
@tabletLimitWidth: 740px;
@desktopLimitWidth: 846px;

html {
    font-family: 'Source Sans Pro', Arial, sans-serif;
}

html,
body,
form,
#root {
    height: 100%;
}

body {
    background-color: @white !important;
}

.limit {
    position: relative;
    margin: 0 auto;
}

.maincontent {
    position: relative;
    padding: 0 !important;
}

.pagewrapper {
    padding-bottom: 0 !important;
}
.sitefooter {
    display: none;
}

.alertstripe, .tips h1, .tips span {
    animation: fadein 1s both;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@media (min-width: @screen-sm-min) {
    .pagewrapper {
        padding-bottom: 200px !important;
    }
    .limit {
        width: @tabletLimitWidth;
    }
    .sitefooter {
        display: block;
    }
    .lukknapp {
        border: 1px solid @navGra60;
        display: block;
        position: relative;
        right: -695px;
        top: 30px;
    }
}


@media (min-width: @screen-md-min) {
    .limit {
        width: @desktopLimitWidth;
    }
}

