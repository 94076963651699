.resultat__oppsummering {
    background-color: @foGraSectionBackground;
    padding: 2rem;
    border-radius: 8px;
    margin-bottom: 2.5rem;

    .oppsummering__container {
        margin: 3rem 7rem 3rem;
    }
    .oppsummering__bilde {
        display: block;
        margin: 0 auto 1rem;
        width: 38vw;
        height: 38vw;
    }
    .oppsummering__overskrift {
        text-align: center;
        margin-bottom: 1.1rem;
    }
}

@media (min-width: @screen-sm-min) {
    .resultat__oppsummering {
        margin: 1rem 0 4rem 0;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        .oppsummering__tekst {
            padding-right: 2rem;
            width: 50%;
        }
        .oppsummering__overskrift {
            text-align: left;
        }
        .oppsummering__bilde {
            display: block;
            width: 30%;
            height: 30%;
            margin: 1rem 2rem 1rem 2rem;
        }
    }
}
