@desktop_skala_bredde: 475px;
@desktop_skala_diameter: 75px;

.alternativer {
    list-style: none;
    padding: 0;
}

.inputPanelGruppe__ettvalg,
.inputPanelGruppe__flervalg {

    li{
        position: relative;
        margin: 0.7rem 0;
        padding: 0;
    }
    .inputPanel {
        height: 100%;
    }
}

.inputPanelGruppe__skala {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-around;
    margin: 2rem 0 2rem 0;
    &:before {
        content: '';
        display: block;
        position: absolute;
        left: 10%;
        right: 10%;
        height: 47%;
        border-bottom: 1px solid @navGra20;
        z-index: -1;
    }
    .alternativ {
        position: relative;
        text-align: center;
        width: 15vw;
        height: 15vw;
        .skjemaelement__input {
            opacity: 0;
            height: 0;
            width: 0;
            padding: 0;
        }
        .skjemaelement__label {
            border-radius: 50%;
            border: 1px solid @navGra20;
            background-color: @white;
            width: 90%;
            height: 90%;
            display: inline-flex;
            justify-content: center;
            align-items: center;

            &.markert {
                color: @navMorkGra;
                border: 1px solid @navBla;
                background-color: @navBlaLighten80;
                animation: markerSkala 0.5s both;
            }
        }
    }
}

@keyframes markerSkala {
    0% {
        transform: scale(1);
    }
    70% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1.35);
    }
}

@media (orientation: landscape) {
    .inputPanelGruppe__skala {
        width: @desktop_skala_bredde;
        height: 100px;
        margin: 2rem auto;
        &:before {
            height: 40px;
        }
        .alternativ {
            width: @desktop_skala_diameter;
            height: @desktop_skala_diameter;
        }
    }
}

@media (min-width: @screen-sm-min) {
    .inputPanelGruppe__ettvalg,
    .inputPanelGruppe__flervalg {
        display: flex;
        justify-content: space-between;
        flex-flow: row wrap;
        li {
            width: 49%;
            margin: 0.35rem 0;
        }
    }

    .inputPanelGruppe__skala {
        margin: 0 auto;
        width: @desktop_skala_bredde;
        height: 100px;
        &:before {
            height: 40px;
        }
        .alternativ {
            width: @desktop_skala_diameter;
            height: @desktop_skala_diameter;
        }
    }
}

#beskrivendetekst {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px; width: 1px;
    margin: -1px; padding: 0; border: 0;
}
