@keyframes raadscale {
	0% {
		transform: scale(0.8);
		margin-top: 30%;
	}
	82% {
		transform: scale(1.03);
		margin-top: -0.9rem;
	}
	100% {
		transform: scale(1.0);
		margin-top: -1rem;
	}
}

@keyframes raadfade {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

body.hodefot_skjult {
	.siteheader, .sitefooter {
		display: none;
	}
}

.raadside {
	.raadside__header {
		margin: 0;
		padding: 1.4rem 1.4rem 0;
		background-color: @foBlaBackground;
		animation: raadscale 0.5s ease-in-out alternate;

		.header.limit {
			.lukknapp {
				display: inline-block;
				position: absolute;
				border: 1px solid @navGra60;
				right: 0;
				top: 0;
				z-index: 100;
			}

			.tilbakelenke {
				display: none;
			}

			.headertekst {
				margin: 0 0 2rem;
				padding-right: 3.5rem;
			}

			.headerbilde {
				display: block;
				position: relative;
				bottom: 0;
				max-width: 400px;
				margin: 0;
			}
		}
	}

	.raadside__innhold {
		margin: 1rem 0 9rem;
		animation: raadfade 0.8s ease-in-out alternate;

		.raad__avsnitt {
			margin: 1.5rem 1.4rem;
			padding: 1.5rem;
			line-height: 1.6rem;
			border-radius: 8px;
			background: @foGraSectionBackground url('/ikoner/ok-sirkel-fylt-sort-hake.svg') no-repeat;
			background-position: ~'calc(100% - 26px)' 24px;
			background-size: 24px 24px;

			&:first-child {
				margin-top: 0;
			}

			p {
				margin-top: 0.5rem;
			}

			.typo-undertittel {
				max-width: ~'calc(100% - 44px)';
			}
		}
	}
}

@media (min-width: @screen-sm-min) {
	body.hodefot_skjult {
		.siteheader, .sitefooter {
			display: block;
		}
	}

	.raadside {
		.raadside__header {
			padding: 0;
			margin: 0;
			display: flex;
			animation: none;

			.header.limit {
				margin-top: 1.5rem;
				position: relative;
				display: flex;
				flex-direction: row;
				justify-content: space-between;

				.lukknapp {
					display: none;
				}

				.tilbakelenke {
					display: inline-block;
					position: absolute;
					top: -0.5rem;

					.chevron--venstre {
						width: 18px;
						margin-right: 5px;
					}

                    .lenke {
                        color: @navMorkGra;
                    }
				}

				.headertekst {
					width: 70%;
					margin: 1.5rem 0 2rem;
					padding-right: 4rem;
					min-height: 5.5rem;
				}

				.headerbilde {
					width: 30%;

					.raadside__headerbilde {
						position: absolute;
						display: block;
						right: -1.5rem;
						bottom: 0;
						width: 280px;
					}
				}
			}
		}

		.raadside__innhold {
			animation: none;
			margin-top: 1.5rem;

			.raad__avsnitt {
				margin: 1.5rem 0;

				&:first-child {
					margin-top: 1.5rem;
				}
			}

			p {
				margin-right: 15%;
			}
		}
	}
}
