@import '../farger';

.limit.limit__startside {
	width: 100%;
}

.startside {
	.startside__bildewrap {
		display: flex;
		justify-content: left;
		align-items: center;
		background-color: @foBlaBackground;
		height: 30vh;

		.startside__bilde {
			width: 9rem;
			height: 9rem;
			min-height: ~'calc(100vh - 382px)';
			margin: 0 auto;
		}
	}
	.startside__innhold {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		text-align: center;
		margin: 0 1.4rem;
		padding-top: 1.5rem;
		.startside__tekst {
			.typo-sidetittel {
				padding: 0 15%;
			}
			.typo-ingress {
				padding: 0 7%;
			}
		}
		.startside__knapperad {
			margin: 2rem 0 1.5rem;
		}
		.startside__knapperad.resultatknapp {
			margin: 1rem 0 1.5rem;
		}
		.startside__footer {
			margin: 0 auto 1.5rem;

			p {
				margin: 20% auto;
				max-width: 19rem;
			}
		}
	}
}

@media (min-width: 375px) and (max-width: 767px) {
	.startside {
		.startside__innhold {
			.startside__tekst {
				.typo-sidetittel {
					padding: 0 18%;
				}
				.typo-ingress {
					padding: 0 15%;
				}
			}
		}
	}
}

@media (min-width: @screen-sm-min) and (max-width: 991px) {
	.startside {
		.startside__innhold {
			.startside__tekst {
				.typo-sidetittel {
					padding: 0 19%;
				}
				.typo-ingress {
					padding: 0 15%;
				}
			}
		}
	}
}

@media (min-width: @screen-md-min) and (max-width: @screen-lg-min) {
	.startside {
		.startside__innhold {
			.startside__tekst {
				.typo-sidetittel {
					padding: 0 25%;
				}
				.typo-ingress {
					padding: 0 25%;
				}
			}
		}
	}
}

@media (orientation: landscape) and (max-width: 767px) {
	.startside {
		.startside__bildewrap {
			height: 100vh;
		}
	}
}

@media (orientation: landscape), (min-width: @screen-sm-min) {
	.startside {
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		padding: 0;
		min-height: 80vh;

		.startside__bildewrap {
			display: flex;
			width: 44%;
			height: 100%;
			margin-bottom: 0;

			.startside__bilde {
				width: 18rem;
				height: 18rem;
				margin: auto;
			}
		}
		.startside__innhold {
			width: 56%;
			height: 100%!important;
			padding-top: 0;
			justify-content: space-around;
			.startside__tekst {
				margin-top: 28%;
			}
			.startside__knapperad {
				margin: 3rem 0 1rem;
				.knapp {
					width: auto;
				}
			}
			.startside__knapperad.resultatknapp {
				 margin: 1rem 0 1.5rem;
			}
		}
	}
}

