@keyframes stroke {
	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes loading {
	0% {
		opacity: 0;
		z-index: 100;
	}
	10% {
		opacity: 1;
		z-index: 100;
	}
	90% {
		opacity: 1;
		z-index: 100;
	}
	100% {
		opacity: 0;
		z-index: -100;
	}
}

@keyframes after-loading {
	0% {
		opacity: 0;
	}
	80% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

body.loading .resultat__loader {
	animation: loading 5s both;
}

.resultat__loader {
	position: fixed;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	background-color: #cde7d8;
	padding: 1rem 1.4rem;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: -100;
	animation: none;
	opacity: 0;
}

.loader__bilde {
	height: 180px;
	width: 180px;
}

.sirkel, .hake {
	transform-origin: 50% 50%;
}

.sirkel {
	stroke-dasharray: 320;
	stroke-dashoffset: 320;
	animation: stroke 2s cubic-bezier(0.65, 0, 0.45, 1) 0.5s forwards;
}

.hake {
	stroke-dasharray: 180;
	stroke-dashoffset: 180;
	animation: stroke 1.5s cubic-bezier(0.65, 0, 0.45, 1) 2.4s forwards;
}

.resultat__header {
	background-color: @foBlaBackground;
	border-bottom: 5px solid @foBlaBorder;

	.header.limit {
		.header__tekst {
			width: 100%;
			padding: 4rem 1.6rem;

			.resultat__overskrift {
				text-align: center;
				line-height: 1rem;
			}

			.resultat__ingress {
				text-align: center;
				margin-top: 1.5rem;
			}
		}

		.header__bilde {
			display: none;
		}
	}
}

body.loading {
	.resultat__innhold,
	.resultat__header,
	.header__bilde,
	.hodefot {
		animation: after-loading 5.5s both;
	}
}

.resultat__innhold {
	padding: 2rem 1.6rem;

	.raadliste__overskrift {
		margin: 0 0 1rem 0.5rem;
		padding-top: 1rem;
	}

	.resultat__raadliste {
		display: flex;
		padding: 3px;
		flex-wrap: nowrap;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;

		.enkelt__raad {
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			min-width: 73vw;
			min-height: 62vw;
			padding: 0.5rem 1.4rem 0;
			border-radius: 8px;
			margin: 0 0.5rem 1.5rem;
			color: @navMorkGra;
			text-decoration: none;
			background-color: @foBlaBackground;

			&:focus {
				outline: 0;
				box-shadow: 0 0 0 3px @orangeFocus;
			}

			.raad__bilde__container {
				position: relative;
				margin-top: 0;
				height: 121px;

				.raad__bilde {
					position: absolute;
					max-width: 100%;
					bottom: 0;
				}
			}

			.raad__tekst__container {
				.raad__heading {
					margin: 1rem 0 0.5rem 0;
				}

				.raad__ingress {
					display: none;
				}
			}
		}
	}

	.resultat__knapper {
		padding: 4.5rem 0 4.5rem;
		text-align: center;

		.resultat__logginn-lagre {
			margin-bottom: 2rem;
		}

		.resultat_lagringsmelding {
			width: 20rem;
			margin: 2rem auto 0;
			color: @navGra60;
		}
	}
}

.resultat__lenker {
	background: @navLysGra;
	padding: 4rem 0 5.5rem;

	.lenker__overskrift {
		margin: 0 auto 2.5rem;
		text-align: center;
	}

	.lenker__innhold {
		margin: 0 auto;
        padding: 0 1.6rem;

		.lenkepanel {
			margin: 0 auto;
			align-items: start;

			&:nth-child(2) {
				margin: 2rem 0 0 0;
			}

			.lenkepanel__indikator {
				margin: auto 0;
			}

			.lenke__tittel {
				margin-bottom: 0.5rem;
			}

			.lenke__ingress {
				padding-right: 1rem;
			}
		}
	}
}

@media (orientation: landscape) {
	.resultat__innhold {
		.raadliste__overskrift {
			padding-top: 0;
		}
		.resultat__raadliste {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			.enkelt__raad {
				width: 49%;
				margin: 0 0 1rem;
				padding: 2rem 2rem 0 2rem;
				min-width: auto;
				min-height: auto;

				.raad__bilde__container {
					height: 121px;
				}

				&:last-child, &:nth-last-child(2) {
					margin-bottom: 0;
				}

				.raad__tekst__container {
					.raad__heading {
						margin: 0 0 0.5rem 0;
					}

					.raad__ingress {
						display: block;
						margin-bottom: 1.5rem;
					}
				}
			}
		}

		.resultat__knapper {
			display: flex;
			flex-direction: column;
			align-items: center;

			.knapp {
				width: 16rem;
			}

			.resultat__logginn-lagre {
				margin-bottom: 4rem;
			}
		}
	}
}

@media (min-width: @screen-sm-min) {
	.resultat__header {
		margin: 0;

		.header.limit {
			display: flex;
			flex-direction: row;

			.header__tekst {
				width: 70%;

				.resultat__overskrift {
					text-align: left;
				}

				.resultat__ingress {
					margin-top: 1.5rem;
					text-align: left;
				}
			}

			.header__bilde {
				display: block;
				position: relative;
				width: 30%;

				img {
					position: absolute;
					bottom: 0;
					right: 1rem;
					width: 170px;
				}
			}
		}
	}

	.resultat__innhold {
		.raadliste__overskrift {
			text-align: center;
			margin-bottom: 2.5rem;
			padding-top: 0;
		}

		.resultat__raadliste {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			.enkelt__raad {
				width: 49%;
				margin: 0 0 1rem;
				padding: 2rem 2rem 0 2rem;
				min-width: auto;
				min-height: 325px;

				.raad__bilde__container {
					height: 121px;
				}

				&:last-child, &:nth-last-child(2) {
					margin-bottom: 0;
				}

				.raad__tekst__container {
					.raad__ingress {
						display: block;
						margin-bottom: 1.5rem;
					}

					.raad__heading {
						margin: 0 0 0.5rem 0;
					}
				}
			}
		}

		.resultat__knapper {
			display: flex;
			flex-direction: column;
			align-items: center;

			.knapp {
				width: 16rem;
			}

			.resultat__logginn-lagre {
				margin-bottom: 4rem;
			}
		}
	}

	.resultat__lenker {
		.lenker__innhold {
			width: 670px;
			display: flex;
			flex-direction: row;

			&.fullbredde {
				width: @desktopLimitWidth;
			}

			.lenkepanel {

				&:nth-child(2) {
					margin: 0 0 0 2rem;
				}

				&.bredde50 {
					width: 50%;
				}
			}
		}
	}
}

@media (min-width: @screen-md-min) {
	.resultat__header {
		.header.limit {
			.header__bilde {
				img {
					width: 200px;
				}
			}
		}
	}
}
